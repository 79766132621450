import SecureLS from 'secure-ls';

class SecureLocalStorageClass {
  private static _instance: SecureLocalStorageClass;

  private _storage: SecureLS;

  private constructor() {
    this._storage = new SecureLS({
      encodingType: 'aes',
      encryptionSecret: '$2a$09$VxJYNLG7qXXVgAbFTvI9qubCa0YL1ww88I7UI6tkPiBEEixs3tlCa'
    });
  }

  public static instance() {
    if (!this._instance) {
      this._instance = new SecureLocalStorageClass();
    }

    return this._instance;
  }

  public setItem = <T = any>(key: string, data: T, encrypt = true) => {
    if (data == null) return;

    encrypt ? this._storage.set(key, data) : localStorage.setItem(key, JSON.stringify(data));
  };

  public getItem = <T = any>(key: string, encrypt = true): T | undefined => {
    const _localStringValue = localStorage.getItem(key);

    return (encrypt ? (this._storage.get(key) as T) : _localStringValue && (JSON.parse(_localStringValue) as T)) || undefined;
  };

  public remove = (key: string) => {
    this._storage.remove(key);
  };
}

export const SecureLocalStorage = SecureLocalStorageClass.instance();

(window as any).setEncryptData = SecureLocalStorage.setItem;
(window as any).getEncryptData = SecureLocalStorage.getItem;
